import React from 'react';
import styled from 'styled-components';

import devices from '../utils/devices';
import SocialBar from './SocialBar';
import { CleanButton } from './styled';

const MainContainer = styled.div`
  background-image: var(--r-g-white);
  border-left: 1px solid var(--clr-main-a);
  border-top: 1px solid var(--clr-main-a);
  padding: 40px 5px 40px 40px;

  span {
    font-size: 1.5rem;
  }

  h1 {
    color: var(--clr-text-c);
    font-size: 4rem;
    font-weight: 900;
    margin-top: 10px;

  }

  h2 {
    color: var(--clr-text-d);
    font-size: 2.5rem;
    margin-bottom: 40px;
  }

  p {
    margin-bottom: 60px;
    max-width: 400px;
  }

  button {
    margin-bottom: 80px;
  }

  @media ${devices.laptopM} {
    h1 {
      font-size: 3.5rem;
    }

    h2 {
      font-size: 2rem;
    }
  }

  @media ${devices.laptopS} {
    button {
      margin-bottom: 0;
    }

    nav {
      display: none;
    }
  }

  @media ${devices.tablet} {
    background-image: unset;
    border: none;
    padding: 0 20px;

    span {
      font-size: 1.2rem;
    }

    h1 {
      font-size: 2.5rem;
    }
  }

  @media ${devices.mobileL} {
    padding: 40px 20px;

    h1 {
      font-size: 2rem;
    }
  }
`;

function Intro() {
  return (
    <MainContainer>
      <span>Boas-vindas! Meu nome é</span>
      <h1>Paulo Henrique Lima.</h1>
      <h2>Eu sou desenvolvedor Full Stack.</h2>
      <p>
        Tenho formação em desenvolvimento web e estou me aprimorando em UX Design,
        desenvolvimento Mobile, e Testes. Meu foco principal é criar produtos e
        experiências digitais úteis, acessíveis e inclusivas.
      </p>
      <CleanButton
        type='button'
        onClick={() => window.open('https://phlima.com/files/resume.pdf', '_blank')}
        $mode='border'
      >
        Meu currículo
      </CleanButton>
      <SocialBar mode='background' />
    </MainContainer>
  );
}

export default Intro;
