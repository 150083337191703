import styled from 'styled-components';

const List = styled.ul`
  list-style: none;
`;

const TagList = styled(List)`
  color: var(--clr-text-b);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  & > li {
    background-color: var(--clr-alt-b);
    border-radius: 2px;
    padding: 5px 10px;
  }
`;

export { TagList };
