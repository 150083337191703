import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

import devices from '../utils/devices';
import rocketSvg from '../assets/svg/rocket.svg';

const BOTTOM = -300;
const DISTANCE_MULTIPLIER = 1.25;

const MainContainer = styled.div`
  bottom: ${BOTTOM}px;
  position: fixed;
  right: -30px;

  img {
    height: 360px;
  }

  @media ${devices.laptopS} {
    display: none;
  }
`;

function Rocket() {
  const ref = useRef();

  const handleScroll = useCallback(() => {
    const scroll = window.scrollY;
    const scrollLimit = document.getElementById('contact').offsetTop;
    const distance = window.innerHeight * DISTANCE_MULTIPLIER;
    const y = (scroll / scrollLimit) * distance + BOTTOM;

    ref.current.style.bottom = `${y}px`;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <MainContainer ref={ref}>
      <img src={rocketSvg} />
    </MainContainer>
  );
}

export default Rocket;
