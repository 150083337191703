import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faGithub,
  faLinkedinIn,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import devices from '../utils/devices';
import { SocialLink } from './styled';

const Navigation = styled.nav`
  column-gap: 40px;
  display: flex;

  @media ${devices.tablet} {
    column-gap: 5px;
  }
`;

function SocialBar({ mode }) {
  return (
    <Navigation>
      <SocialLink
        href='https://www.linkedin.com/in/limapaulobsb/'
        target={'_blank'}
        rel='noreferrer'
        $mode={mode}
      >
        <FontAwesomeIcon icon={faLinkedinIn} />
      </SocialLink>
      <SocialLink
        href='https://github.com/limapaulobsb'
        target={'_blank'}
        rel='noreferrer'
        $mode={mode}
      >
        <FontAwesomeIcon icon={faGithub} />
      </SocialLink>
      <SocialLink
        href='https://twitter.com/limapaulobsb'
        target={'_blank'}
        rel='noreferrer'
        $mode={mode}
      >
        <FontAwesomeIcon icon={faTwitter} />
      </SocialLink>
      <SocialLink
        href='https://www.instagram.com/limapaulobsb/'
        target={'_blank'}
        rel='noreferrer'
        $mode={mode}
      >
        <FontAwesomeIcon icon={faInstagram} />
      </SocialLink>
    </Navigation>
  );
}

SocialBar.propTypes = {
  mode: PropTypes.string,
}.isRequired;

export default SocialBar;
