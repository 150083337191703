import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import devices from '../utils/devices';
import { Cloud } from './styled';

const Container = styled.div`
  bottom: ${(props) => props.$bottom}px;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: fixed;
  width: 100%;

  @media ${devices.laptopS} {
    display: none;
  }
`;

function CloudRow({
  bottom,
  distanceMultiplier,
  horizontalSpacing,
  verticalSpacing,
  sizes,
}) {
  const ref = useRef();

  const handleScroll = useCallback(() => {
    const scroll = window.scrollY;
    const scrollLimit = document.getElementById('contact').offsetTop;
    const distance = window.innerHeight * distanceMultiplier;
    const y = (scroll / scrollLimit) * distance + bottom;

    ref.current.style.bottom = `${y}px`;
  }, [bottom, distanceMultiplier]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Container ref={ref} $bottom={bottom}>
      {sizes.map((size, index) => (
        <Cloud
          key={index}
          $size={size}
          $left={horizontalSpacing[index]}
          $top={verticalSpacing[index]}
        />
      ))}
    </Container>
  );
}

function Clouds() {
  return (
    <>
      <CloudRow
        bottom={-150}
        distanceMultiplier={1}
        sizes={[0.6, 0.5]}
        horizontalSpacing={[10, -50]}
        verticalSpacing={[20, 0]}
      />
      <CloudRow
        bottom={-300}
        distanceMultiplier={0.8}
        sizes={[0.8, 0.7]}
        horizontalSpacing={[180, -60]}
        verticalSpacing={[-20, 0]}
      />
      <CloudRow
        bottom={-300}
        distanceMultiplier={0.9}
        sizes={[0.6, 0.8]}
        horizontalSpacing={[40, -160]}
        verticalSpacing={[40, -20]}
      />
    </>
  );
}

CloudRow.propTypes = {
  bottom: PropTypes.number.isRequired,
  distanceMultiplier: PropTypes.number.isRequired,
  horizontalSpacing: PropTypes.arrayOf(PropTypes.number).isRequired,
  verticalSpacing: PropTypes.arrayOf(PropTypes.number).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Clouds;
