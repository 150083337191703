// Icons from https://devicon.dev/
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import skills from '../skills.json';
import SkillCard from './SkillCard';
import { CleanButton } from './styled';

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  max-width: 800px;

  h2 {
    font-size: 1.8rem;
  }

  hr {
    background-image: var(--l-g-line);
    border: none;
    height: 2px;
    margin: 20px 0 100px;
    width: 100px;
  }

  button {
    &:first-of-type {
      align-self: flex-end;
      margin-bottom: 20px;
    }
  }
`;

const CardContainer = styled.div`
  column-gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-height: ${({ height }) => height};
  overflow-y: hidden;
`;

function Skills() {
  const [isOpen, setIsOpen] = useState(false);
  const [sortType, setSortType] = useState('rating');
  const [sortedSkills, setSortedSkills] = useState(skills);

  useEffect(() => {
    const arr = [...skills];

    if (sortType === 'rating') {
      arr.sort((a, b) => b.rating - a.rating);
    } else {
      arr.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
    }

    setSortedSkills(arr);
  }, [sortType]);

  return (
    <MainContainer>
      <h2>Skills</h2>
      <hr />
      <CleanButton
        type='button'
        onClick={() => {
          setSortType(sortType === 'rating' ? 'name' : 'rating');
        }}
      >
        <span>{sortType === 'rating' ? 'Avaliação' : 'A-Z'}</span>
        <FontAwesomeIcon icon={sortType === 'rating' ? faChevronDown : faChevronUp} />
      </CleanButton>
      <CardContainer height={isOpen ? 'fit-content' : '900px'}>
        {sortedSkills.map(({ name, icon, rating }, index) => (
          <SkillCard key={index} name={name} icon={icon} rating={rating} />
        ))}
      </CardContainer>
      <CleanButton type='button' onClick={() => setIsOpen(!isOpen)} $mode='border'>
        <span>Ver {isOpen ? 'menos' : 'mais'}</span>
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </CleanButton>
    </MainContainer>
  );
}

export default Skills;
