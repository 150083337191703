import React from 'react';
import styled from 'styled-components';

import devices from '../utils/devices';
import { ImageCard } from './styled';
import ProfilePicture from '../assets/images/profile-picture.jpg';

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin: 40px 0 120px;
  width: 100%;

  @media ${devices.laptopM} {
    align-items: center;
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  max-width: 800px;

  h2 {
    font-size: 2.5rem;
    font-weight: normal;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: normal;
    margin-bottom: 40px;
  }

  p {
    margin-bottom: 20px;
  }

  @media ${devices.laptopM} {
    margin: 0 40px 80px;
  }

  @media ${devices.mobileL} {
    margin: 0 20px 80px;

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.2rem;
    }
  }
`;

function Presentation() {
  return (
    <MainContainer>
      <TextContainer>
        <h2>Olá! 👋</h2>
        <h3>
          Eu sou Paulo, <s>webmaster</s> desenvolvedor full stack vivendo em Brasília.
        </h3>
        <p>
          Já ouviu falar em webmaster? Pois é... Quando essa expressão era utilizada, eu
          ainda estava no colégio, mas com muita curiosidade e autonomia, já fazia meus
          primeiros sites. Desde então, meus estudos e trabalhos foram em diversas áreas,
          como engenharia, TI, comunicação, administração e empreendedorismo, até decidir
          seguir minha paixão pelo desenvolvimento. Iniciei minha formação em 2021 com o
          curso de Desenvolvimento Web da Trybe, e hoje sou graduando em Engenharia de
          Software no Instituto de Educação Superior de Brasília.
        </p>
        <p>
          Tenho conhecimento em várias tecnologias e linguagens de programação, e
          experiência em diversos projetos. O que me destaca como desenvolvedor é minha
          grande capacidade de aprender e me adaptar, e me sentir bastante à vontade em
          ambientes de colaboração e diversidade.
        </p>
        <p>
          Se você tiver uma oportunidade de trabalho que corresponda às minhas habilidades
          e experiência, não hesite em entrar em contato! Estou animado para trabalhar em
          projetos desafiadores e construir soluções criativas que possam fazer a
          diferença!
        </p>
      </TextContainer>
      <div>
        <ImageCard>
          <img src={ProfilePicture} alt='Foto de Paulo Henrique Lima' />
          <div>
            <span>Paulo Lima</span>
            <span>27/11/21</span>
          </div>
        </ImageCard>
      </div>
    </MainContainer>
  );
}

export default Presentation;
