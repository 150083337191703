import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

import { IconButton } from './styled';

function ColorModeButton() {
  const [mode, setMode] = useState('light');

  const toggleColorMode = () => {
    if (mode === 'light') {
      setMode('dark');
    } else {
      setMode('light');
    }
  };

  useEffect(() => {
    const { style } = document.documentElement;

    if (mode === 'light') {
      style.setProperty('--clr-alt-a', 'rgb(255 255 180)');
      style.setProperty('--clr-alt-b', 'rgb(225 225 150)');
      style.setProperty('--clr-bg-a', 'rgb(240 240 255)');
      style.setProperty('--clr-bg-b', 'rgb(255 255 255)');
      style.setProperty('--clr-contrast', 'rgb(180 120 30)');
      style.setProperty('--clr-main-a', 'rgb(90 60 150)');
      style.setProperty('--clr-main-b', 'rgb(150 120 210)');
      style.setProperty('--clr-text-a', 'rgb(240 240 255)');
      style.setProperty('--clr-text-b', 'rgb(30 15 45)');
      style.setProperty('--clr-text-c', 'rgb(30 15 45)');
      style.setProperty('--clr-text-d', 'rgb(240 240 180)');
    } else {
      style.setProperty('--clr-alt-a', 'rgb(180 90 210)');
      style.setProperty('--clr-alt-b', 'rgb(150 60 180)');
      style.setProperty('--clr-bg-a', 'rgb(30 30 30)');
      style.setProperty('--clr-bg-b', 'rgb(45 45 45)');
      style.setProperty('--clr-contrast', 'rgb(210 60 60)');
      style.setProperty('--clr-main-a', 'rgb(0 0 15)');
      style.setProperty('--clr-main-b', 'rgb(90 60 150)');
      style.setProperty('--clr-text-a', 'rgb(225 225 255)');
      style.setProperty('--clr-text-b', 'rgb(255 255 255)');
      style.setProperty('--clr-text-c', 'rgb(225 225 255)');
      style.setProperty('--clr-text-d', 'rgb(240 120 240)');
    }
  }, [mode]);

  return (
    <IconButton type='button' onClick={toggleColorMode} $textColor={'var(--clr-text-d)'}>
      <FontAwesomeIcon icon={mode === 'light' ? faSun : faMoon} />
    </IconButton>
  );
}

export default ColorModeButton;
