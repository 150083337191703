import React from 'react';
import styled from 'styled-components';

import devices from '../utils/devices';
import SocialBar from './SocialBar';
import cloudsSvg from '../assets/svg/clouds.svg';

const MainContainer = styled.footer`
  align-items: center;
  background-image: url(${cloudsSvg});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  min-height: 25vw;
  width: 100%;

  nav {
    margin-bottom: 60px;
  }

  @media ${devices.laptopS} {
    min-height: 300px;
  }
`;

function Footer() {
  return (
    <MainContainer>
      <SocialBar />
      <span>©2023 phlima.com</span>
    </MainContainer>
  );
}

export default Footer;
