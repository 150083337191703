import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import PuffLoader from 'react-spinners/PuffLoader';

import devices from '../utils/devices';
import { ContrastButton, MainCard, MainInput, MainTextArea } from './styled';
import paperPlanesImage from '../assets/images/paper-planes.png';

const MainContainer = styled(MainCard)`
  margin-bottom: 200px;
  padding-bottom: 60px;

  &::after {
    aspect-ratio: 3 / 2;
    background-image: url(${paperPlanesImage});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -60px;
    content: '';
    left: 50%;
    position: absolute;
    transform: translate(-50%, 50%);
    width: 360px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 5px;
  }

  input,
  textarea {
    margin-bottom: 20px;
  }

  @media ${devices.tablet} {
    &::after {
      bottom: -40px;
      width: 300px;
    }
  }

  @media ${devices.mobileL} {
    padding-bottom: 40px;
  }

  @media ${devices.mobileS} {
    &::after {
      width: 240px;
    }
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;

  & > div {
    align-items: center;
    display: flex;
    height: 60px;
  }

  @media ${devices.mobileL} {
    flex-direction: column-reverse;
  }
`;

const inputReset = {
  user_name: '',
  user_email: '',
  message: '',
};

function ContactForm() {
  const formRef = useRef();
  const [inputValues, setInputValues] = useState(inputReset);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = ({ target: { name, value } }) => {
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const result = await emailjs.sendForm(
        'service_3o7egbb',
        'template_gjy2vhs',
        formRef.current,
        'user_o0g718gN6Vx0RKvoZVwFJ'
      );

      setInputValues({ ...inputReset });
      setStatusMessage('Mensagem enviada!');
      console.log(result.text);
    } catch (error) {
      setStatusMessage('Ocorreu um erro.');
      console.log(error.text);
    }

    setIsLoading(false);
  };

  // Field validations
  useEffect(() => {
    const REGEX = /\S+@\S+\.\S+/;
    const { user_name, user_email, message } = inputValues;

    if (user_name.length > 5 && message.length > 9 && REGEX.test(user_email)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [inputValues]);

  return (
    <MainContainer>
      <form ref={formRef} onSubmit={handleSubmit}>
        <label htmlFor='name-input'>Nome</label>
        <MainInput
          type='text'
          id='name-input'
          name='user_name'
          placeholder='Digite seu nome'
          value={inputValues.user_name}
          onChange={handleChange}
        />
        <label htmlFor='email-input'>E-mail</label>
        <MainInput
          type='email'
          id='email-input'
          name='user_email'
          placeholder='Digite seu e-mail'
          value={inputValues.user_email}
          onChange={handleChange}
        />
        <label htmlFor='message-input'>Mensagem</label>
        <MainTextArea
          id='message-input'
          name='message'
          placeholder='Digite sua mensagem'
          value={inputValues.message}
          onChange={handleChange}
        />
        <ButtonContainer>
          <div>
            {isLoading ? (
              <PuffLoader
                color={document.documentElement.style.getPropertyValue(
                  '--clr-contrast'
                )}
              />
            ) : (
              <span>{statusMessage}</span>
            )}
          </div>
          <ContrastButton type='submit' disabled={isLoading || !isValid}>
            Enviar
          </ContrastButton>
        </ButtonContainer>
      </form>
    </MainContainer>
  );
}

export default ContactForm;
