import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';

import devices, { screenSizes } from '../utils/devices';
import ColorModeButton from './ColorModeButton';
import SocialBar from './SocialBar';
import { IconButton, RainbowButton } from './styled';
import logoSvg from '../assets/svg/logo.svg';

const HEIGHT_COLLAPSED = 110;
const HEIGHT_EXPANDED = 640;
const PADDING_TOP = 10;

const ExpandableContainer = styled(motion.header)`
  align-items: center;
  background-color: var(--clr-main-a);
  box-shadow: var(--b-s-none);
  display: flex;
  height: ${HEIGHT_COLLAPSED}px;
  justify-content: space-between;
  left: 0;
  overflow-y: hidden;
  padding: ${PADDING_TOP}px 80px 0;
  position: fixed;
  top: 0;
  transition: box-shadow 400ms ease, top 400ms ease-in-out;
  width: 100%;
  z-index: 200;

  ${(props) =>
    props.$highlighted &&
    css`
      box-shadow: var(--b-s-m);
    `}

  @media ${devices.laptopS} {
    padding: ${PADDING_TOP}px 40px 0;
  }

  @media ${devices.tablet} {
    display: block;
  }
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${HEIGHT_COLLAPSED - PADDING_TOP}px;
  justify-content: space-between;

  img {
    height: 50px;
  }

  button {
    visibility: hidden;
  }

  @media ${devices.tablet} {
    width: 100%;

    button {
      visibility: visible;
    }
  }

  @media ${devices.mobileL} {
    img {
      height: 40px;
    }
  }
`;

const MainNavigation = styled.nav`
  align-items: center;
  display: flex;
  gap: 20px;

  nav {
    display: none;
  }

  @media ${devices.tablet} {
    flex-direction: column;
    gap: 40px;
    padding: 40px 0;
    width: 100%;

    nav {
      display: flex;
    }
  }
`;

const containerVariants = {
  collapsed: {
    height: `${HEIGHT_COLLAPSED}px`,
  },

  expanded: {
    height: `${HEIGHT_EXPANDED}px`,
  },
};

const containerTransition = { type: 'spring', damping: 15, stiffness: 150 };

function Header() {
  const headerRef = useRef();
  const [isAtTheTop, setIsAtTheTop] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [lastHeight, setLastHeight] = useState(document.body.clientHeight);
  const [lastY, setLastY] = useState(window.scrollY);

  const handleResize = useCallback(() => {
    if (window.innerWidth > screenSizes.tablet) {
      setIsExpanded(false);
    }
  }, []);

  const handleScroll = useCallback(() => {
    const height = document.body.clientHeight;
    const y = window.scrollY;

    // Checks scroll direction and prevents execution when height is changed
    // or Header is expanded
    if (lastY > y && lastHeight === height && !isExpanded) {
      headerRef.current.style.top = `${-PADDING_TOP}px`;

      if (y === 0) {
        headerRef.current.style.top = 0;
        setIsAtTheTop(true);
      }
    } else if (lastY < y && lastHeight === height && !isExpanded) {
      headerRef.current.style.top = `${-HEIGHT_COLLAPSED - PADDING_TOP}px`;
      setIsAtTheTop(false);
    }

    setLastHeight(document.body.clientHeight);
    setLastY(window.scrollY);
  }, [isExpanded, lastHeight, lastY]);

  const toggleContainer = () => {
    setIsExpanded(!isExpanded);
  };

  const scrollTo = (section) => {
    window.scrollTo(0, document.getElementById(section).offsetTop);
    setIsExpanded(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleResize, handleScroll]);

  return (
    <ExpandableContainer
      ref={headerRef}
      animate={isExpanded ? 'expanded' : 'collapsed'}
      transition={containerTransition}
      variants={containerVariants}
      $highlighted={!isAtTheTop || isExpanded}
    >
      <LogoContainer>
        <img src={logoSvg} />
        <IconButton
          type='button'
          onClick={toggleContainer}
          $textColor={'var(--clr-text-d)'}
        >
          <FontAwesomeIcon icon={isExpanded ? faClose : faBars} />
        </IconButton>
      </LogoContainer>
      <MainNavigation>
        <RainbowButton type='button' onClick={() => scrollTo('about')}>
          <span>Sobre mim</span>
        </RainbowButton>
        <RainbowButton type='button' onClick={() => scrollTo('projects')}>
          <span>Projetos</span>
        </RainbowButton>
        <RainbowButton type='button' onClick={() => scrollTo('contact')}>
          <span>Contato</span>
        </RainbowButton>
        <ColorModeButton />
        <SocialBar />
      </MainNavigation>
    </ExpandableContainer>
  );
}

export default Header;
