import styled, { css } from 'styled-components';

import devices from '../../utils/devices';

const Anchor = styled.a`
  align-items: center;
  display: flex;
  color: inherit;
  justify-content: center;
  position: relative;
  text-decoration: none;
  z-index: 100;
`;

const ProjectLink = styled(Anchor)`
  border: 1px solid var(--clr-text-b);
  height: 80px;
  width: 180px;
`;

const SocialLink = styled(Anchor)`
  aspect-ratio: 1;
  border-radius: 50%;
  font-size: 1.8rem;
  opacity: 75%;
  width: 90px;

  &:hover {
    opacity: 100%;
  }

  @media ${devices.tablet} {
    font-size: 1.5rem;
    width: 80px;
  }

  @media ${devices.mobileL} {
    width: 70px;
  }

  @media ${devices.mobileM} {
    font-size: 1.2rem;
    width: 60px;
  }

  @media ${devices.mobileS} {
    width: 50px;
  }

  ${(props) =>
    props.$mode === 'background' &&
    css`
      background-color: var(--clr-main-a);
      box-shadow: var(--b-s-m);
      color: var(--clr-text-d);
      top: 0;
      transition: box-shadow 200ms ease-in-out, top 200ms ease-in-out;

      &:hover {
        box-shadow: var(--b-s-m-hover);
        top: -4px;
      }
    `}
`;

export { ProjectLink, SocialLink };
