import styled from 'styled-components';

const Cloud = styled.div`
  background-color: white;
  border-radius: 40px;
  box-shadow: 0 4px 4px 0 rgb(0 0 15 / 10%), -2px 2px 4px 0 rgb(0 0 15 / 10%) inset;
  height: 50px;
  left: ${(props) => props.$left}px;
  position: relative;
  top: ${(props) => props.$top}px;
  transform: scale(${(props) => props.$size});
  width: 140px;

  &::before {
    aspect-ratio: 1;
    background-color: inherit;
    border-radius: 50%;
    box-shadow: -1px 3px 2px 0 rgb(0 0 15 / 15%) inset;
    content: '';
    left: 20px;
    position: absolute;
    top: -20px;
    width: 40px;
  }

  &::after {
    aspect-ratio: 1;
    background-color: inherit;
    border-radius: 50%;
    box-shadow: -1px 4px 2px 0 rgb(0 0 15 / 15%) inset;
    content: '';
    position: absolute;
    right: 20px;
    top: -35px;
    width: 70px;
  }
`;

export default Cloud;
