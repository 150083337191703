import styled from 'styled-components';

import starSvg from '../../assets/svg/star.svg';

const Rating = styled.div`
  --h: 24px;

  height: var(--h);
  position: relative;
  width: calc(var(--h) * 5);

  &::before {
    background-image: url(${starSvg});
    background-size: contain;
    content: '';
    height: var(--h);
    left: 0;
    opacity: 10%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::after {
    background-image: url(${starSvg});
    background-size: contain;
    content: '';
    height: var(--h);
    left: 0;
    opacity: 75%;
    position: absolute;
    top: 0;
    width: ${(props) => (props.rating / 5) * 100}%;
  }
`;

export default Rating;
