import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { OctagonalCard, Rating } from './styled';

const MainContainer = styled(OctagonalCard)`
  margin-bottom: 80px;
`;

function SkillCard({ name, icon, rating }) {
  return (
    <MainContainer>
      <h6>{name}</h6>
      <i className={icon}></i>
      <Rating rating={rating} />
      <div className='background'></div>
      <div className='border-bottom'></div>
    </MainContainer>
  );
}

SkillCard.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
};

export default SkillCard;
