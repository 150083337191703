import styled from 'styled-components';

import devices from '../../utils/devices';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;
`;

const ImageCard = styled(Card)`
  background-color: white;
  border-radius: 2px;
  box-shadow: var(--b-s-xl);
  padding: 20px;
  transform: rotateZ(2deg);

  img {
    aspect-ratio: 1;
    width: 300px;
  }

  div {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    span {
      color: black;
      font-family: var(--font-c);
      font-size: 1.5rem;
    }
  }

  @media ${devices.tablet} {
    img {
      width: 240px;
    }
  }

  @media ${devices.mobileL} {
    padding: 15px;

    img {
      width: 210px;
    }
  }

  @media ${devices.mobileM} {
    img {
      width: 180px;
    }
  }
`;

const MainCard = styled(Card)`
  background-color: var(--clr-bg-a);
  border-radius: 4px;
  box-shadow: var(--b-s-xl);
  color: var(--clr-text-b);
  max-width: 800px;
  padding: 40px;
  width: 100%;

  h2 {
    font-size: 2rem;
  }

  @media ${devices.tablet} {
    padding: 40px 10px;
  }
`;

const OctagonalCard = styled(Card)`
  align-items: center;
  aspect-ratio: 1;
  color: var(--clr-text-a);
  isolation: isolate;
  justify-content: center;
  width: 220px;

  h6 {
    font-size: 0.75rem;
    letter-spacing: 1px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  i {

    font-size: 5rem;
    margin-bottom: 10px;
  }

  .background,
  .border-bottom {
    inset: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &::before {
      background-color: var(--clr-main-b);
      content: '';
      inset: 0;
      position: absolute;
      transform: rotate(45deg);
    }
  }

  .border-bottom {
    transform: translateY(4px);
    z-index: -2;

    &::before {
      background: var(--l-g-alihossein);
    }
  }
`;

export { ImageCard, MainCard, OctagonalCard };
