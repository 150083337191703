import React from 'react';
import styled from 'styled-components';

import devices from '../utils/devices';

import {
  Carousel,
  Clouds,
  ContactForm,
  Footer,
  Header,
  Intro,
  Presentation,
  Rocket,
  Skills,
} from '../components';

import astronautSvg from '../assets/svg/astronaut.svg';

const MainContainer = styled.main`
  background-image: var(--l-g-main);
  font-size: 1.2rem;

  @media ${devices.mobileL} {
    font-size: 1rem;
  }
`;

const HomeSection = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-around;
  min-height: 100vh;
  padding-top: 110px;

  img {
    width: 540px;
  }

  @media ${devices.laptopL} {
    img {
      width: 420px;
    }
  }

  @media ${devices.laptopM} {
    img {
      width: 360px;
    }
  }

  @media ${devices.laptopS} {
    flex-direction: column-reverse;
    justify-content: space-evenly;

    img {
      margin: 10px 0;
    }
  }

  @media ${devices.tablet} {
    img {
      width: 300px;
    }
  }

  @media ${devices.mobileL} {
    img {
      width: 210px;
    }
  }
`;

const Section = styled.section`
  display: flex;
  min-height: 100vh;
  position: relative;
  z-index: 10;

  &:not(:first-of-type) {
    align-items: center;
    flex-direction: column;
    padding: 120px 5px;
  }

  & > h1 {
    font-size: 2rem;
    text-transform: uppercase;

    &::before {
      content: '<';
      margin-right: 10px;
      opacity: 20%;
    }

    &::after {
      content: '/>';
      margin-left: 10px;
      opacity: 20%;
    }
  }

  & > hr {
    background-image: var(--l-g-line);
    border: none;
    height: 8px;
    margin: 20px 0 60px;
    width: 100px;
  }

  & > span {
    font-size: 1.5rem;
    margin-bottom: 40px;
    max-width: 720px;
    text-align: center;
  }

  @media ${devices.tablet} {
    & > h1 {
      font-size: 2rem;
    }

    & > span {
      font-size: 1.2rem;
    }
  }
`;

function Home() {
  return (
    <MainContainer>
      <Header />
      <HomeSection>
        <Intro />
        <img src={astronautSvg} />
      </HomeSection>
      <Section id='about'>
        <h1>Sobre mim</h1>
        <hr />
        <Presentation />
        <Skills />
      </Section>
      <Section id='projects'>
        <h1>Projetos</h1>
        <hr />
        <span>Estes são alguns dos meus projetos mais relevantes.</span>
        <Carousel />
      </Section>
      <Section id='contact'>
        <h1>Contato</h1>
        <hr />
        <span>
          Obrigado por reservar um tempo para entrar em contato. Envie o formulário abaixo
          e retornarei o mais breve possível.
        </span>
        <ContactForm />
      </Section>
      <Footer />
      <Clouds />
      <Rocket />
    </MainContainer>
  );
}

export default Home;
