import styled, { css, keyframes } from 'styled-components';

import devices from '../../utils/devices';

const Button = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: inherit;
  justify-content: center;
  position: relative;
  z-index: 100;

  &:disabled {
    cursor: not-allowed;
    opacity: 50%;
  }
`;

const ArrowButton = styled(Button)`
  aspect-ratio: 1;
  background-color: var(--clr-bg-b);
  border-radius: 50%;
  box-shadow: var(--b-s-m);
  color: var(--clr-text-b);
  font-size: 1.5rem;
  width: 60px;
`;

const CleanButton = styled(Button)`
  border-radius: 2px;
  padding: 20px;
  width: 180px;

  span {
    border-bottom: 1px solid var(--clr-text-a);
    flex-grow: 10;
    text-align: start;
    margin-right: 20px;
    padding-bottom: 5px;
  }

  ${(props) =>
    props.$mode === 'border' &&
    css`
      border: 1px solid var(--clr-text-a);

      span {
        border-bottom: none;
        padding-bottom: 0;
      }
    `}
`;

const ContrastButton = styled(Button)`
  aspect-ratio: 3;
  background-color: var(--clr-contrast);
  background-image: var(--l-g-white);
  border: 2px solid var(--clr-contrast);
  box-shadow: var(--b-s-m);
  color: white;
  font-family: var(--font-b);
  min-width: 180px;

  @media ${devices.mobileL} {
    min-width: 150px;
  }
`;

const IconButton = styled(Button)`
  aspect-ratio: 1;
  color: ${(props) => props.$textColor};
  font-size: 1.8rem;
  width: 40px;
`;

const SelectorButton = styled(Button)`
  aspect-ratio: 1;
  background-color: var(--clr-contrast);
  border-radius: 50%;
  opacity: ${(props) => (props.active ? '100%' : '40%')};
  width: 20px;
`;

const play = keyframes`
  to {
    background-position: var(--w);
  }
`;

const RainbowButton = styled(Button)`
  --b: 2px;
  --h: 60px;
  --w: 200px;

  animation: ${play} 1500ms linear infinite;
  animation-play-state: paused;
  background-image: var(--l-g-rainbow);
  background-position: 0;
  height: var(--h);
  min-width: var(--w);

  &:hover {
    animation-play-state: running;
  }

  span {
    align-items: center;
    background: var(--clr-main-a);
    color: white;
    display: flex;
    font-family: var(--font-b);
    font-size: 1rem;
    height: calc(var(--h) - 2 * var(--b));
    justify-content: center;
    letter-spacing: 1px;
    min-width: calc(var(--w) - 2 * var(--b));
    text-transform: uppercase;
  }

  @media ${devices.laptopS} {
    --h: 50px;
    --w: 160px;
  }

  @media ${devices.tablet} {
    --h: 60px;
    --w: 200px;
  }
`;

export {
  ArrowButton,
  CleanButton,
  ContrastButton,
  IconButton,
  RainbowButton,
  SelectorButton,
};
