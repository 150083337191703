import styled from 'styled-components';

const TextArea = styled.textarea`
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  resize: none;

  &::placeholder {
    color: var(--clr-gray);
    transition: opacity 200ms ease-in-out;
  }

  &:focus::placeholder {
    opacity: 0;
  }
`;

const MainTextArea = styled(TextArea)`
  background-color: var(--clr-bg-b);
  border-radius: 2px;
  box-shadow: var(--b-s-s);
  height: 200px;
  padding: 20px;
`;

export { MainTextArea };
