import styled from 'styled-components';

const Input = styled.input`
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;

  &::placeholder {
    color: var(--clr-gray);
    transition: opacity 200ms ease-in-out;
  }

  &:focus::placeholder {
    opacity: 0;
  }
`;

const MainInput = styled(Input)`
  background-color: var(--clr-bg-b);
  border-radius: 2px;
  box-shadow: var(--b-s-s);
  height: 50px;
  padding: 0 20px;
`;

export { MainInput };
