import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faChevronLeft,
  faChevronRight,
  faPause,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';

import devices from '../utils/devices';
import projects from '../projects.json';

import {
  ArrowButton,
  IconButton,
  MainCard,
  ProjectLink,
  SelectorButton,
  TagList,
} from './styled';

const MainContainer = styled(MainCard)`
  h2 {
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 60px;
  }

  @media ${devices.tablet} {
    align-items: center;

    ul {
      justify-content: center;
    }
  }
`;

const ControlContainer = styled.div`
  button {
    position: absolute;

    &:first-child {
      left: 60px;
      top: 60px;
    }

    &:nth-child(2) {
      left: -60px;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:nth-child(3) {
      right: -60px;
      top: 50%;
      transform: translate(50%, -50%);
    }
  }

  @media ${devices.laptopS} {
    display: none;
  }
`;

const Slider = styled.div`
  display: flex;
  margin-bottom: 40px;
  /* max-width: 720px; */
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  width: 100%;
`;

const Slide = styled.div`
  background-image: url(${(props) => props.screenshot});
  background-position: top;
  background-size: cover;
  height: 240px;
  min-width: 100%;
  opacity: ${(props) => (props.active ? 1 : 0.8)};
  scroll-snap-align: start;
  transition: opacity 200ms ease-in-out;
`;

const SelectorContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 40px;
`;

const Navigation = styled.nav`
  display: flex;
  justify-content: space-evenly;

  @media ${devices.tablet} {
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
`;

function Carousel() {
  const sliderRef = useRef();
  const timeoutRef = useRef();
  const [auto, setAuto] = useState(true);
  const [projectIndex, setProjectIndex] = useState(0);

  const projectCount = projects.length;

  const moveTo = useMemo(
    () => ({
      next: () => {
        if (projectIndex === projectCount - 1) setProjectIndex(0);
        else setProjectIndex(projectIndex + 1);
      },
      previous: () => {
        if (projectIndex === 0) setProjectIndex(projectCount - 1);
        else setProjectIndex(projectIndex - 1);
      },
      project: (index) => {
        setProjectIndex(index);
      },
    }),
    [projectCount, projectIndex]
  );

  useEffect(() => {
    if (auto) {
      const timeout = setTimeout(() => {
        moveTo.next();
      }, 4000);

      timeoutRef.current = timeout;
    }

    return () => clearTimeout(timeoutRef.current);
  }, [auto, moveTo]);

  useEffect(() => {
    const slider = sliderRef.current;

    slider.scrollTo({
      behavior: 'smooth',
      left: projectIndex * slider.offsetWidth,
    });
  }, [projectIndex]);

  return (
    <MainContainer>
      <ControlContainer>
        <IconButton
          type="button"
          onClick={() => {
            if (!auto) moveTo.next();
            setAuto(!auto);
          }}
          $textColor="white"
        >
          <FontAwesomeIcon icon={auto ? faPause : faPlay} />
        </IconButton>
        <ArrowButton
          type="button"
          onClick={() => {
            moveTo.previous();
            setAuto(false);
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </ArrowButton>
        <ArrowButton
          type="button"
          onClick={() => {
            moveTo.next();
            setAuto(false);
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </ArrowButton>
      </ControlContainer>
      <Slider ref={sliderRef}>
        {projects.map((project, index) => {
          return (
            <Slide
              key={index}
              screenshot={project.screenshot_url}
              active={index === projectIndex}
            />
          );
        })}
      </Slider>
      <SelectorContainer>
        {Array(projectCount)
          .fill()
          .map((_, index) => (
            <SelectorButton
              type="button"
              key={index}
              onClick={() => {
                moveTo.project(index);
                setAuto(false);
              }}
              active={index === projectIndex}
            />
          ))}
      </SelectorContainer>
      <h2>{projects[projectIndex].name}</h2>
      <TagList>
        {projects[projectIndex].tags.map((tag, index) => (
          <li key={index}>{tag}</li>
        ))}
      </TagList>
      <Navigation>
        {projects[projectIndex].demo_url !== '' ? (
          <ProjectLink
            href={projects[projectIndex].demo_url}
            target="_blank"
            rel="noreferrer"
          >
            Demo
          </ProjectLink>
        ) : null}
        <ProjectLink
          href={projects[projectIndex].github_repository}
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </ProjectLink>
      </Navigation>
    </MainContainer>
  );
}

export default Carousel;
